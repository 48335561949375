import {
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  Divider,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";

const CustomerInfo = ({
  handleNext,
  handleChange,
  handlePhone,
  values: { firstName, lastName, email, returningCustomer },
  formErrors,
  phone,
}) => {
  const isValid =
    firstName.length > 0 &&
    !formErrors.firstName &&
    lastName.length > 0 &&
    !formErrors.lastName &&
    email.length > 0 &&
    !formErrors.email &&
    phone.length === 15;

  return (
    <>
      <Grid container spacing={2} sx={{ minHeight: 300 }}>
        <Grid item xs={12} sx={{ m: 2 }}>
          <Typography variant="h6" align="center">
            Please enter your contact information
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            margin="normal"
            fullWidth
            required
            label="First Name"
            name="firstName"
            placeholder="Your first name"
            value={firstName || ""}
            onChange={handleChange}
            error={!!formErrors.firstName}
            helperText={formErrors.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            margin="normal"
            fullWidth
            required
            label="Last Name"
            name="lastName"
            placeholder="Your last name"
            value={lastName || ""}
            onChange={handleChange}
            error={!!formErrors.lastName}
            helperText={formErrors.lastName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            margin="normal"
            fullWidth
            required
            label="Email"
            name="email"
            placeholder="Your email address"
            value={email || ""}
            onChange={handleChange}
            error={!!formErrors.email}
            helperText={formErrors.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MuiTelInput
            preferredCountries={["CA", "US"]}
            defaultCountry="CA"
            regions={"america"}
            variant="standard"
            margin="normal"
            fullWidth
            required
            label="Phone Number"
            name="phone"
            value={phone || ""}
            onChange={handlePhone}
            inputProps={{ maxLength: 15, minLength: 15 }}
            error={phone.length < 15 && phone.length > 2}
            helperText={
              phone.length < 15 && phone.length > 2
                ? "Please enter a valid phone number. i.e: +X xxx xxx xxxx"
                : ""
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
          <FormGroup>
            <FormLabel id="returningCustomer-checkbox">
              Please choose if you are a returning customer.
            </FormLabel>
            <FormControlLabel
              label="Returning Customer"
              control={
                <Checkbox
                  name="returningCustomer"
                  checked={returningCustomer}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "returningCustomer-checkbox" }}
                />
              }
            />
          </FormGroup>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          sx={{ mt: 3, ml: 1 }}
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
        >
          Next
        </Button>
      </Box>
    </>
  );
};

export default CustomerInfo;
