import { Typography, Link, Container } from "@mui/material";

const Footer = () => {
  const linkSX = {
    "&:hover": {
      color: "blue",
    },
  };

  // const footerStyle = {
  //   display: "block",
  //   padding: "20px",
  //   height: "60px",
  //   width: "100%",
  // };

  return (
    // <Box>
    //   <div style={footerStyle}>
    //     <footer
    //       style={{
    //         backgroundColor: "#fff",
    //         borderTop: "1px solid #E7E7E7",
    //         textAlign: "center",
    //         padding: "20px",
    //         position: "flex-end",
    //         left: "0",
    //         bottom: "15px",
    //         height: "60px",
    //         width: "100%",
    //       }}
    //     >
    //       <Typography variant="body2" color="textSecondary" align="center">
    //         {new Date().getFullYear()} Copyright &copy;{" "}
    //         <Link
    //           sx={linkSX}
    //           color="inherit"
    //           href="https://tiagosautospa.com/"
    //           rel="noreferrer"
    //         >
    //           Tiago's Auto Spa
    //         </Link>
    //         {". All rights reserved."}
    //       </Typography>

    //       <Typography variant="body2" color="textSecondary" align="center">
    //         {"Designed By "}
    //         <Link
    //           sx={linkSX}
    //           color="inherit"
    //           href="https://github.com/s-rezayi"
    //           rel="noreferrer"
    //         >
    //           Soheil
    //         </Link>
    //       </Typography>
    //     </footer>
    //   </div>
    // </Box>
    // <Paper
    //   sx={{
    //     marginTop: "auto",
    //     // width: "100%",
    //     // position: "flex-end",
    //     bottom: 0,
    //   }}
    //   component="footer"
    //   square
    //   // variant="outlined"
    // >
    <Container maxWidth="lg" sx={{ mt: "auto" }}>
      {/* <Typography variant="body2" color="textSecondary" align="center">
          {new Date().getFullYear()} Copyright &copy;{" "}
          <Link
            sx={linkSX}
            color="inherit"
            href="https://tiagosautospa.com/"
            rel="noreferrer"
          >
            Tiago's Auto Spa
          </Link>
          {". All rights reserved."}
        </Typography> */}

      <Typography variant="body2" color="textSecondary" align="center">
        {"Designed By "}
        <Link
          sx={linkSX}
          color="inherit"
          href="https://github.com/s-rezayi"
          rel="noreferrer"
        >
          Soheil
        </Link>
      </Typography>
    </Container>
    // </Paper>
  );
};

export default Footer;
