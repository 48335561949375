import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Paper,
  ButtonBase,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import api from "../api/posts";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100px",
  maxHeight: "100px",
});

const PkgTitle = ({
  handleNext,
  handleBack,
  handleChange,
  values: { vehicleType, packageCategory, packageInfo },
}) => {
  const isValid = packageInfo?.title;

  const [pkgs, setPkgs] = useState([]);

  useEffect(() => {
    const packageList = async () => {
      try {
        const response = await api.post("app/package/", {
          category: packageCategory,
          type: vehicleType,
        });
        console.log(response.data);
        const data = response.data;
        setPkgs(data);
      } catch (err) {
        if (err.response) {
          // Not in the 200 response range
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
          // setFetchError(true);
        } else {
          console.log(err);
          // setFetchError(true);
        }
      }
    };

    packageList();
  }, [packageCategory, vehicleType]);

  // console.log(packageInfo)

  const handleEstimatedTime = (pkg) => {
    if (pkg?.overnight_stay === 0) {
      return `${pkg?.estimated_time_first_day} hours`;
    }
    return `${pkg?.overnight_stay} ${pkg?.overnight_stay > 1 ? "days" : "day"}`;
  };

  return (
    <>
      <Grid container spacing={2} sx={{ minHeight: 300 }}>
        <Grid item xs={12} sx={{ m: 2 }}>
          <Typography variant="h6" align="center">
            Please select the package
          </Typography>
          <Divider />
        </Grid>

        {pkgs.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0)).map((pkg) => {
          return (
            <Grid item xs={12} key={pkg.id}>
              <Paper
                key={pkg.id}
                sx={{
                  justifyContent: "center",
                  p: 2,
                  margin: "auto",
                  maxWidth: 600,
                  flexGrow: 1,
                  backgroundColor:
                    packageInfo?.title === pkg.title
                      ? "selectedc.main"
                      : "selectc.main",
                }}
              >
                <Grid container spacing={2} justifyContent="center">
                  <Grid item>
                    <ButtonBase sx={{ width: 128, height: 128 }}>
                      <Img alt="complex" src={require("./pkgcat/Tiago2.png")} />
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Typography gutterBottom variant="h6" component="div">
                          {pkg.title}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {pkg.description}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Estimated Time: {handleEstimatedTime(pkg)}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          id={pkg.title}
                          name="packageInfo"
                          sx={{
                            maxWidth: 300,
                            backgroundColor:
                              packageInfo?.title === pkg.title
                                ? "primary"
                                : "buttonSelectc.main",
                          }}
                          variant="contained"
                          value={JSON.stringify(pkg)}
                          onClick={(e) => handleChange(e)}
                        >
                          {packageInfo["title"] === pkg.title
                            ? "Selected"
                            : "Select"}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle1" component="div">
                        {pkg.price} $*
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          );
        })}
        {vehicleType !== "Bike" && (
          <Grid item xs={12} sx={{ m: 3 }}>
            <Typography variant="body2" align="center">
              * Prices vary based on vehicle size and condition
              (algae, pet hair, mold, etc). Final price given upon vehicle
              assessment.
            </Typography>
          </Grid>
        )}
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
        >
          Next
        </Button>
      </Box>
    </>
  );
};

export default PkgTitle;
