import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Box,
  Divider,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import api from "../api/posts";
import FetchError from "./FetchError";

// const businessHours = {};

// for (let i = 9; i < 19; i++){
//   businessHours[i] = i.toString()
// }

const SelectDate = ({
  handleNext,
  handleBack,
  handleDropoff,
  handlePickup,
  handleDropoffTimePicker,
  handlePickupTimePicker,
  handleChange,
  dropoff,
  pickup,
  dropoffTimePicker,
  pickupTimePicker,
  selectedAddons,
  values: { packageInfo, packageCategory, customDropoff },
}) => {
  // const pkgs = require("./pkgs.json");
  // const cat = pkgs[packageCategory];

  const packageId = packageInfo.id;
  const addonIds = selectedAddons.map((addon) => addon.id);

  const [impossibleDates, setImpossibleDates] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [latestDropoff, setLatestDropoff] = useState("9");
  const [pickupDate, setPickupDate] = useState(0);
  const [pickupTime, setPickupTime] = useState(
    packageInfo.title === "Car Wash" ? 2 : 8
  );
  const [isSameDay, setIsSameDay] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  useEffect(() => {
    const unavailableDates = async () => {
      try {
        const response = await api.post("app/unavailable/", {
          packageId: packageId,
          addonIds: addonIds,
        });
        setImpossibleDates(response.data.impossible);
        setHolidays(response.data.holidays);
        setLatestDropoff(response.data.latest_drop_off.toString());
        setPickupDate(response.data.pickup_min_date);
        setPickupTime(response.data.pickup_min_time);
        if (response.data.pickup_min_date === 0) {
          setIsSameDay(true);
        }
      } catch (err) {
        if (err.response) {
          // Not in the 200 response range
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
          setFetchError(true);
        } else {
          console.log(err);
          setFetchError(true);
        }
      }
    };
    unavailableDates();
  }, [packageId]);

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const disableDatesDropoff = (day) => {
    return (
      day?.day() === 0 ||
      impossibleDates.includes(day?.toISOString().split("T")[0])
    );
  };

  const disableDatesPickup = (day) => {
    return (
      day?.day() === 0 || holidays.includes(day?.toISOString().split("T")[0])
    );
  };

  const handlePickupDate = (pickupD, drop) => {
    // const minDate = dropoff?.add(pickupDate, "day");
    let count = 0;
    for (let i = 0; i <= pickupD; i++) {
      const cDate = drop?.add(i, "day");
      if (disableDatesPickup(cDate)) {
        count += 1;
      }
    }
    // console.log(count);
    return count;
  };

  const handlePickupTimeLimits = (pickupD, pickupT, dropT) => {
    // console.log(dropT);
    if (packageInfo.title === "Car Wash") {
      return dropT - 9 + 2;
    }
    if (pickupD === 0) {
      return 8;
    }

    return pickupT;
  };

  const dropoffTimePickerMin = dayjs("09", "HH");
  const dropoffTimePickerMax = dayjs(latestDropoff, "HH");

  const pickupTimePickerMin = dayjs("9", "HH").add(
    handlePickupTimeLimits(pickupDate, pickupTime, dropoffTimePicker?.hour()),
    "hour"
  );
  const pickupTimePickerMax = dayjs("18:01", "HH:mm");

  // const handleDropoffTimeLimits = (pkgTitle) => {
  //   if (pkgTitle === "Car Wash") {
  //     return -3;
  //   }
  //   return -8;
  // };
  // console.log(pickupDate)
  // console.log(pickupDate + handlePickupDate(pickupDate, dropoff) + pickupDate !== 0 ? 1 : 0)

  const isValid =
    dropoff &&
    pickup &&
    dropoffTimePicker &&
    pickupTimePicker &&
    dropoffTimePicker.diff(dropoffTimePicker.hour(9).minute(0), "minute") >=
      0 &&
    dropoffTimePicker.diff(dropoffTimePicker.hour(18).minute(0), "minute") <=
      0 &&
    pickupTimePicker.diff(pickupTimePicker.hour(9).minute(0), "minute") >= 0 &&
    pickupTimePicker.diff(pickupTimePicker.hour(18).minute(0), "minute") <= 0 &&
    pickup >= dropoff &&
    pickupTimePicker > dropoffTimePicker;

  if (fetchError) {
    return (
      <>
        <FetchError />
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
        </Box>
      </>
    );
  }
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ m: 2 }}>
          <Typography variant="h6" align="center">
            Please select the date and time for drop-off and pick-up
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={6}>
          <MobileDatePicker
            value={dropoff}
            onChange={handleDropoff}
            shouldDisableDate={disableDatesDropoff}
            label="Drop-off Date"
            disablePast
            minDate={dayjs().add(1, "d")}
            maxDate={dayjs().add(3, "M")}
            renderInput={(params) => (
              <TextField
                onKeyDown={onKeyDown}
                {...params}
                InputProps={{
                  readOnly: true,
                }}
                {...params}
                label="Drop-off Date"
                variant="standard"
                margin="normal"
                fullWidth
                required
                error={false}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <MobileTimePicker
            value={dropoffTimePicker}
            onChange={handleDropoffTimePicker}
            disabled={!dropoff || customDropoff}
            label="Drop-off time"
            minutesStep={15}
            minTime={dropoffTimePickerMin}
            maxTime={dropoffTimePickerMax}
            renderInput={(params) => (
              <TextField
                onKeyDown={onKeyDown}
                {...params}
                InputProps={{
                  readOnly: true,
                }}
                label="Drop-off time"
                variant="standard"
                margin="normal"
                fullWidth
                required
                error={
                  dropoffTimePicker < dropoffTimePickerMin ||
                  dropoffTimePicker > dropoffTimePickerMax.add(1, "minute")
                }
                helperText={
                  dropoffTimePicker < dropoffTimePickerMin ||
                  dropoffTimePicker > dropoffTimePickerMax.add(1, "minute")
                    ? `Drop-off time must be between ${dropoffTimePickerMin.hour()}:00 and ${dropoffTimePickerMax.hour()}:00`
                    : ""
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <MobileDatePicker
            value={pickup}
            onChange={handlePickup}
            shouldDisableDate={disableDatesPickup}
            disabled={!dropoff}
            label="Pick-up Date"
            disablePast
            minDate={dropoff?.add(
              isSameDay
                ? 0
                : pickupDate + handlePickupDate(pickupDate, dropoff),
              "day"
            )}
            maxDate={dropoff?.add(
              isSameDay
                ? 0
                : pickupDate + handlePickupDate(pickupDate, dropoff) + 1,
              "day"
            )}
            defaultCalendarMonth={dropoff?.add(
              isSameDay
                ? 0
                : pickupDate + handlePickupDate(pickupDate, dropoff),
              "day"
            )}
            renderInput={(params) => (
              <TextField
                onKeyDown={onKeyDown}
                {...params}
                InputProps={{
                  readOnly: true,
                }}
                {...params}
                label="Pick-up Date"
                variant="standard"
                margin="normal"
                fullWidth
                required
                error={pickup ? pickup < dropoff : false}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <MobileTimePicker
            value={pickupTimePicker}
            onChange={handlePickupTimePicker}
            disabled={!pickup}
            label="Pick-up time"
            minutesStep={15}
            minTime={pickupTimePickerMin}
            maxTime={pickupTimePickerMax}
            renderInput={(params) => (
              <TextField
                onKeyDown={onKeyDown}
                {...params}
                InputProps={{
                  readOnly: true,
                }}
                {...params}
                label="Pick-up time"
                variant="standard"
                margin="normal"
                fullWidth
                required
                error={
                  pickupTimePicker < pickupTimePickerMin ||
                  pickupTimePicker > pickupTimePickerMax.add(1, "minute")
                }
                helperText={
                  pickupTimePicker < pickupTimePickerMin ||
                  pickupTimePicker > pickupTimePickerMax.add(1, "minute")
                    ? `Pick-up time must be between ${pickupTimePickerMin.hour()}:00 and ${pickupTimePickerMax.hour()}:00`
                    : ""
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
          <FormGroup>
            <FormLabel id="customDropoff-checkbox">
              Please choose if you need to drop off you vehicle during
              non-business hours.
            </FormLabel>
            <FormControlLabel
              label="Non-business hours drop off"
              control={
                <Checkbox
                  id="customDropoff-checkbox"
                  name="customDropoff"
                  checked={customDropoff}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "customDropoff-checkbox" }}
                />
              }
            />
          </FormGroup>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
        >
          Next
        </Button>
      </Box>
    </>
  );
};

export default SelectDate;
