import { Typography, Box } from "@mui/material";
// import dayjs from "dayjs";
// import { useEffect } from "react";
// import api from "../api/posts";

const Success = ({
  values: { firstName, email, packageInfo },
  dropoff,
  pickup,
  dropoffTimePicker,
  pickupTimePicker,
}) => {

  return (
    <>
      <Box>
        <Typography variant="h5" align="center" sx={{ fontWeight: "bold" }}>
          **ATTENTION**{" "}
        </Typography>
        <Typography variant="h5" align="center" marginTop={2}>
          We have received your request.
        </Typography>
        <Typography variant="h5" align="center">
          Your appointment is{" "}
          <Typography variant="p" align="center" sx={{ fontWeight: "bold" }}>
            not confirmed
          </Typography>
        </Typography>

        <Typography variant="h6" align="center">
          until we send you an email confirmation shortly.
        </Typography>

        <Typography component="p" align="center" margin={2}>
          We will send you further instructions via email to {email}.
        </Typography>
      </Box>
    </>
  );
};

export default Success;
