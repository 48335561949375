import dayjs from "dayjs";
import {
  Grid,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Chip,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  Link,
  TextField,
} from "@mui/material";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Vancouver");

const Confirm = ({
  handleNext,
  handleBack,
  handleChange,
  phone,
  vehicleYear,
  vehicleMake,
  vehicleModel,
  dropoff,
  pickup,
  dropoffTimePicker,
  pickupTimePicker,
  selectedAddons,
  values: {
    firstName,
    lastName,
    email,
    vehicleType,
    vehicleColor,
    vehicleCondition,
    coated,
    ppf,
    pkgs,
    packageInfo,
    confirmAdditionalNote,
    agreement,
  },
}) => {
  // const pkgs = require("./pkgs.json");
  // const cat = pkgs[packageCategory];

  const isValid = agreement;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ m: 2 }}>
          <Typography variant="h6" align="center">
            Please Confirm the appointment Information
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12} sm={6}>
          <List>
            <Divider>
              <Chip label="Contact Information" />
            </Divider>
            <ListItem>
              <ListItemText
                primary="First Name"
                secondary={firstName || "Not Provided"}
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemText
                primary="Last Name"
                secondary={lastName || "Not Provided"}
              />
            </ListItem>
            <Divider />

            <ListItem>
              <ListItemText
                primary="Email Address"
                secondary={email || "Not Provided"}
              />
            </ListItem>
            <Divider />

            <ListItem>
              <ListItemText
                primary="Phone Number"
                secondary={phone || "Not Provided"}
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} sm={6}>
          <List>
            <Divider>
              <Chip label="Vehicle" />
            </Divider>
            <ListItem>
              <ListItemText
                primary="Vehicle Type"
                secondary={vehicleType || "Not Provided"}
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemText
                primary="Vehicle"
                secondary={
                  `${vehicleCondition} ${
                    vehicleYear ? dayjs(vehicleYear).year() : ""
                  } ${vehicleColor} ${
                    vehicleMake === "Other" ? "Not specified" : vehicleMake
                  } ${vehicleModel === "Other" ? "" : vehicleModel}` ||
                  "Not Provided"
                }
              />
            </ListItem>
            <Divider />

            <ListItem>
              <ListItemText
                primary="Is the vehicle ceramic coated?"
                secondary={coated ? "Yes" : "No"}
              />
            </ListItem>
            <Divider />

            <ListItem>
              <ListItemText
                primary="Is the vehicle wrapped?"
                secondary={ppf ? "Yes" : "No"}
              />
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} sm={6}>
          <List>
            <Divider>
              <Chip label="Package" />
            </Divider>
            <ListItem>
              <ListItemText
                primary="Package Name"
                secondary={packageInfo.title || "Not Provided"}
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemText
                primary="Package Price"
                secondary={
                  `${
                    packageInfo.price
                  } CAD` || "Not Provided"
                }
              />
            </ListItem>
            <Divider />
          </List>
        </Grid>

        <Grid item xs={12} sm={6}>
          <List>
            <Divider>
              <Chip label="Add on" />
            </Divider>
            <ListItem>
              <ListItemText
                primary="Add on List"
                // comma separated list of addons.title
                secondary={
                  selectedAddons.length > 0
                    ? selectedAddons.map((addon) => addon.title).join(", ")
                    : "Not Provided"
                }
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemText
                primary="Add on Price"
                // sum of addons.price
                secondary={
                  selectedAddons.length > 0
                    ? `${selectedAddons.reduce(
                        (sum, addon) => sum + Number(addon.price),
                        0
                      )}.00 CAD`
                    : "Not Provided"
                }
              />
            </ListItem>
            <Divider />
          </List>
        </Grid>

        <Grid item xs={12} sm={6}>
          <List>
            <Divider>
              <Chip label="Date and Time" />
            </Divider>
            <ListItem>
              <ListItemText
                primary="Drop-off Date and Time"
                secondary={
                  dayjs(dropoff)
                    .hour(dropoffTimePicker.hour())
                    .minute(dropoffTimePicker.minute())
                    .toDate()
                    .toLocaleString() || "Not Provided"
                }
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemText
                primary="pick-up date and time"
                secondary={
                  dayjs(pickup)
                    .hour(pickupTimePicker.hour())
                    .minute(pickupTimePicker.minute())
                    .toDate()
                    .toLocaleString() || "Not Provided"
                }
              />
            </ListItem>
            <Divider />
          </List>
        </Grid>

        <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
          <FormLabel>
            Is there any additional considerations regarding this appointment?
          </FormLabel>
          <TextField
            sx={{ mt: 0 }}
            variant="standard"
            margin="normal"
            fullWidth
            multiline
            maxRows={3}
            label="Additional Note"
            name="confirmAdditionalNote"
            value={confirmAdditionalNote || ""}
            onChange={handleChange}
          />
        </Grid>

        {/* <Grid item xs={12} sm={6} sx={{ mt: 1 }}></Grid> */}

        <Grid item xs={12} sm={12} sx={{ mt: 1 }}>
          <FormGroup>
            <FormLabel id="vehicleProtection-checkbox">
              Please check the box to indicate you have read and agree to the
              terms of{" "}
              <Link href="#" underline="always">
                {"Tiago's Auto Spa Customer Agreement"}
              </Link>
              {"."}
            </FormLabel>
            <FormControlLabel
              sx={{ mt: 2 }}
              label="I agree to the Terms of Service and Privacy Policy"
              control={
                <Checkbox
                  // sx={{mt: 1}}
                  name="agreement"
                  checked={agreement}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "agreement-checkbox" }}
                />
              }
            />
          </FormGroup>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
        <Button
          id="confirm"
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
        >
          Confirm
        </Button>
      </Box>
    </>
  );
};

export default Confirm;
