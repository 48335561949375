import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline, Container, Paper } from "@mui/material";
import Header from "./components/Header";
import StepForm from "./components/StepForm";
import Footer from "./components/Footer";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { SnackbarProvider } from "notistack";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    selectc: {
      main: "#ABADB8",
    },
    selectedc: {
      main: "#6D6D71",
    },
    buttonSelectc: {
      main: "#8B9CF1",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={1}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Header />
          <Container component="main" maxWidth="lg" sx={{ mb: 4, height: 100}}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <StepForm />
            </Paper>
            <Footer />
          </Container>
          
        </LocalizationProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
