import { useState, useEffect } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  // MobileStepper,
  // Grid,
} from "@mui/material";
import CustomerInfo from "./CustomerInfo";
import VehicleInfo from "./VehicleInfo";
import SelectPkg from "./SelectPkg";
import SelectAddOn from "./SelectAddOn";
import SelectDate from "./SelectDate";
import Confirm from "./Confirm";
import Success from "./Success";
import formValidation from "./formValidation";
import PkgTitle from "./PkgTitle";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import api from "../api/posts";
import FetchError from "./FetchError";

// const labels = ["Contact", "Vehicle", "category", "Package", "Date", "Confirm"];
const labels = [1, 2, 3, 4, 5, 6, 7];
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  vehicleType: "",
  vehicleColor: "",
  packageId: "",
  packageCategory: "",
  packageInfo: {},
  addonInfo: [],
  vehicleCondition: "",
  coated: false,
  ppf: false,
  customDropoff: false,
  vehicleAdditionalNote: "",
  confirmAdditionalNote: "",
  agreement: false,
  returningCustomer: false,
};

const fieldsValidation = {
  firstName: {
    error: "",
    validate: "text",
    minLength: 2,
    maxLength: 20,
  },
  lastName: {
    error: "",
    validate: "text",
    minLength: 2,
    maxLength: 20,
  },
  email: {
    error: "",
    validate: "email",
  },
  vehicleType: {},
  vehicleMake: {
    error: "",
    validate: "text-number",
    minLength: 2,
    maxLength: 20,
  },
  vehicleModel: {
    error: "",
    validate: "text-number",
    minLength: 2,
    maxLength: 20,
  },
  vehicleColor: {},
  vehicleCondition: {},
  coated: {
    error: "",
    validate: "checkbox",
  },
  ppf: {
    error: "",
    validate: "checkbox",
  },
  customDropoff: {
    error: "",
    validate: "checkbox",
  },
  returningCustomer: {
    error: "",
    validate: "checkbox",
  },
  vehicleAdditionalNote: {},
  confirmAdditionalNote: {},
  packageId: {},
  packageCategory: {},
  packageInfo: {},
  addonInfo: {},
  agreement: {},
};

const StepForm = () => {
  const [fetchError, setFetchError] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState(
    JSON.parse(localStorage.getItem("stored_values")) || initialValues
  );
  const [formErrors, setFormErrors] = useState({});
  const [dropoff, setDropoff] = useState(null);
  const [pickup, setPickup] = useState(null);
  const [dropoffTimePicker, setDropoffTimePicker] = useState(
    dayjs().set("hour", 9).set("minute", 0)
  );
  const [pickupTimePicker, setPickupTimePicker] = useState(
    dayjs().set("hour", 17).set("minute", 0)
  );
  const [phone, setPhone] = useState(
    JSON.parse(localStorage.getItem("stored_phone")) || ""
  );
  const [vehicleYear, setVehicleYear] = useState(
    JSON.parse(localStorage.getItem("stored_year")) || null
  );

  const [vehicleMake, setVehicleMake] = useState(
    JSON.parse(localStorage.getItem("stored_make"))
  );
  const [vehicleModel, setVehicleModel] = useState(
    JSON.parse(localStorage.getItem("stored_model")) || null
  );
  const [modelOptions, setModelOptions] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);

  useEffect(() => {
    const vehicleModelOptions = async () => {
      try {
        const response = await api.post("app/car_info/", {
          make: vehicleMake,
        });
        // console.log(response.data);
        const data = response.data;
        data.push("Other");
        setModelOptions(data);
      } catch (err) {
        if (err.response) {
          // Not in the 200 response range
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
          setFetchError(true);
        } else {
          console.log(err);
          setFetchError(true);
        }
      }
    };

    vehicleModelOptions();
  }, [vehicleMake]);

  const handleVehicleMake = async (e, newValue) => {
    localStorage.setItem("stored_make", JSON.stringify(newValue));
    setVehicleMake(newValue);
    setVehicleModel(null);
  };

  const handleVehicleModel = (e, value) => {
    localStorage.setItem("stored_model", JSON.stringify(value));
    setVehicleModel(value);
  };

  const handleYearChange = (value) => {
    localStorage.setItem("stored_year", JSON.stringify(dayjs(value)));
    setVehicleYear(dayjs(value));
  };

  const handlePhone = (newPhone) => {
    localStorage.setItem("stored_phone", JSON.stringify(newPhone));
    setPhone(newPhone);
  };

  // Proceed to next step
  const handleNext = async (e) => {
    setActiveStep((prev) => prev + 1);

    if (e.target.id === "confirm") {
      const appointmentValues = { ...formValues };
      appointmentValues["packageTitle"] = formValues.packageInfo.title;
      appointmentValues["addonIds"] = selectedAddons.map((addon) => addon.id);
      appointmentValues["phone"] = phone;
      appointmentValues["vehicleYear"] = vehicleYear;
      appointmentValues["vehicleMake"] = vehicleMake;
      appointmentValues["vehicleModel"] = vehicleModel;
      if (formValues.customDropoff) {
        appointmentValues["dropoff"] = dayjs(dropoff).hour(0).minute(0).utc();
      } else {
        appointmentValues["dropoff"] = dayjs(dropoff)
          .hour(dropoffTimePicker.hour())
          .minute(dropoffTimePicker.minute())
          .utc();
      }
      appointmentValues["pickup"] = dayjs(pickup)
        .hour(pickupTimePicker.hour())
        .minute(pickupTimePicker.minute())
        .utc();

      // console.log(appointmentValues);

      try {
        const response = await api.post("app/submit/", {
          values: appointmentValues,
        });
        // console.log(response.data);
      } catch (err) {
        if (err.response) {
          // Not in the 200 response range
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
          setFetchError(true);
        } else {
          console.log(err);
          setFetchError(true);
        }
      }

      // Track the form submission event
      if (typeof window.gtag === "function") {
        console.log("gtag is a function");
        window.gtag("event", "submit_form", {
          event_category: "Form",
          event_label: "Form Submission",
          value: 1,
        });
      }
    }
  };

  // Go back to prev step
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const { enqueueSnackbar } = useSnackbar();

  const handleDropoff = (newDropoff) => {
    setPickup(null);
    setDropoffTimePicker(dayjs().set("hour", 9).set("minute", 0));
    setPickupTimePicker(dayjs().set("hour", 17).set("minute", 0));
    setDropoff(newDropoff);
  };

  const handleDropoffTimePicker = (newValue) => {
    setDropoffTimePicker(newValue);
  };

  const handlePickupTimePicker = (newValue) => {
    setPickupTimePicker(newValue);
  };

  const handlePickup = (newPickup, sameDay, drop) => {
    if (sameDay) {
      setPickup(drop.add(8, "hour"));
    }
    setPickup(newPickup);
  };

  // Handle form change
  const handleChange = (e) => {
    const type = e.target.type;
    const name = e.target.name;

    let value = type === "checkbox" ? e.target.checked : e.target.value;

    if (name === "vehicleType") {
      localStorage.setItem("stored_make", JSON.stringify("Other"));
      localStorage.setItem("stored_model", JSON.stringify("Other"));
      setVehicleMake("Other");
      setVehicleModel("Other");
    }

    if (name === "packageCategory") {
      enqueueSnackbar(`${e.target.id} is selected`, {
        variant: "success",
        preventDuplicate: true,
      });
      setDropoff(null);
      setPickup(null);
    }

    if (name === "packageInfo") {
      value = JSON.parse(value);
      formValues["packageId"] = value.id;
      enqueueSnackbar(`${e.target.id} is selected`, {
        variant: "success",
        preventDuplicate: true,
      });
      setDropoff(null);
      setPickup(null);
    }

    if (name === "addonInfo") {
      value = JSON.parse(value);
      const addonIndex = selectedAddons.findIndex(
        (addon) => addon.id === value.id
      );
      console.log("selectedAddons in step form", selectedAddons);

      if (addonIndex >= 0) {
        // Remove the addon if it's already selected
        setSelectedAddons(
          selectedAddons.filter((addon, index) => index !== addonIndex)
        );
        enqueueSnackbar(`${value.title} deselected`, {
          variant: "warning",
          preventDuplicate: true,
        });
      } else {
        // Add the addon if it's not selected
        setSelectedAddons([...selectedAddons, value]);
        enqueueSnackbar(`${value.title} selected`, {
          variant: "success",
          preventDuplicate: true,
        });
      }

      setDropoff(null);
      setPickup(null);
    }

    if (name === "customDropoff") {
      setDropoffTimePicker(dayjs().set("hour", 9).set("minute", 0));
    }

    if (name !== "addonInfo") {
      setFormValues((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    // set errors
    const error = formValidation(name, value, fieldsValidation) || "";

    setFormErrors({
      [name]: error,
    });
  };

  // local storage
  useEffect(() => {
    localStorage.setItem("stored_values", JSON.stringify(formValues));
  }, [formValues]);

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        if (fetchError) {
          return <FetchError />;
        }
        return (
          <CustomerInfo
            handleNext={handleNext}
            handleChange={handleChange}
            handlePhone={handlePhone}
            phone={phone}
            values={formValues}
            formErrors={formErrors}
          />
        );
      case 1:
        return (
          <VehicleInfo
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            handleYearChange={handleYearChange}
            handleVehicleMake={handleVehicleMake}
            handleVehicleModel={handleVehicleModel}
            vehicleYear={vehicleYear}
            vehicleMake={vehicleMake}
            vehicleModel={vehicleModel}
            modelOptions={modelOptions}
            values={formValues}
            formErrors={formErrors}
          />
        );
      case 2:
        return (
          <SelectPkg
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            values={formValues}
          />
        );
      case 3:
        return (
          <PkgTitle
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            values={formValues}
          />
        );
      case 4:
        return (
          <SelectAddOn
            selectedAddons={selectedAddons}
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            values={formValues}
          />
        );
      case 5:
        return (
          <SelectDate
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            handleDropoff={handleDropoff}
            handlePickup={handlePickup}
            handleDropoffTimePicker={handleDropoffTimePicker}
            handlePickupTimePicker={handlePickupTimePicker}
            dropoff={dropoff}
            pickup={pickup}
            dropoffTimePicker={dropoffTimePicker}
            pickupTimePicker={pickupTimePicker}
            selectedAddons={selectedAddons}
            values={formValues}
          />
        );
      case 6:
        return (
          <Confirm
            handleBack={handleBack}
            handleNext={handleNext}
            handleChange={handleChange}
            values={formValues}
            phone={phone}
            vehicleYear={vehicleYear}
            vehicleMake={vehicleMake}
            vehicleModel={vehicleModel}
            dropoff={dropoff}
            pickup={pickup}
            dropoffTimePicker={dropoffTimePicker}
            pickupTimePicker={pickupTimePicker}
            selectedAddons={selectedAddons}
          />
        );
      default:
        break;
    }
  };

  if (fetchError) {
    return <FetchError />;
  }

  return (
    <>
      {activeStep === labels.length ? (
        // Last Component
        <Success
          values={formValues}
          dropoff={dropoff}
          pickup={pickup}
          dropoffTimePicker={dropoffTimePicker}
          pickupTimePicker={pickupTimePicker}
          handleBack={handleBack}
        />
      ) : (
        <>
          <Box style={{ margin: "10px 0 10px" }}>
            <Typography variant="h4" align="center">
              Booking a new appointment
            </Typography>
          </Box>
          {/* <Box style={{ margin: "10px 0 10px" }}>
            <MobileStepper
              variant="progress"
              steps={6}
              position="static"
              activeStep={activeStep}
              style={{ margin: "30px 0 15px" }}
              // sx={{ flexGrow: 1 }}
            ></MobileStepper>
          </Box> */}
          <Stepper
            activeStep={activeStep}
            style={{ margin: "30px 0 15px" }}
            alternativeLabel
          >
            {labels.map((label) => (
              <Step key={label}>
                <StepLabel>
                  <Typography variant="caption">{""}</Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {handleSteps(activeStep)}
        </>
      )}
    </>
  );
};

export default StepForm;
