import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  FormGroup,
  Checkbox,
  Divider,
  Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import api from "../api/posts";

const VehicleInfo = ({
  handleNext,
  handleBack,
  handleChange,
  handleYearChange,
  handleVehicleModel,
  handleVehicleMake,
  values: {
    vehicleType,
    vehicleColor,
    vehicleCondition,
    coated,
    ppf,
    vehicleAdditionalNote,
  },
  vehicleYear,
  vehicleMake,
  vehicleModel,
  modelOptions,
  formErrors,
}) => {
  const isValid = vehicleType.length > 0 && vehicleCondition.length > 0;

  const [makeOptions, setMakeOptions] = useState([]);
  const [inputMake, setInputMake] = useState("");
  // const [inputModel, setInputModel] = useState("");

  useEffect(() => {
    const unavailableDates = async () => {
      // console.log(packageId)
      try {
        const response = await api.get("app/car_info/");
        // console.log(response.data);
        setMakeOptions(response.data);
      } catch (err) {
        if (err.response) {
          // Not in the 200 response range
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          console.log(err);
        }
      }
    };

    unavailableDates();
  }, []);

  return (
    <>
      <Grid container spacing={2} sx={{ minHeight: 300 }}>
        <Grid item xs={12} sx={{ m: 2 }}>
          <Typography variant="h6" align="center">
            Please enter your vehicle information
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <FormLabel
              id="vehicleType-radio-buttons-group"
              required
              focused={false}
            >
              Type
            </FormLabel>
            <RadioGroup
              aria-labelledby="vehicleType-radio-buttons-group"
              name="vehicleType"
              // row
              value={vehicleType}
              onChange={handleChange}
            >
              <FormControlLabel
                value="2-Seater"
                control={<Radio />}
                label="2-Seater"
              />
              <FormControlLabel
                value="4-SeaterCoupe"
                control={<Radio />}
                label="4-Seater Coupe"
              />
              <FormControlLabel
                value="Sedan"
                control={<Radio />}
                label="Sedan"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <Typography sx={{ color: "#fff" }}>Vehicle Type</Typography>
            <FormLabel id="vehicleType-radio-buttons-group"></FormLabel>
            <RadioGroup
              aria-labelledby="vehicleType-radio-buttons-group"
              name="vehicleType"
              // row
              value={vehicleType}
              onChange={handleChange}
            >
              <FormControlLabel value="CUV" control={<Radio />} label="CUV" />
              <FormControlLabel value="SUV" control={<Radio />} label="SUV" />
              <FormControlLabel
                value="Large SUV"
                control={<Radio />}
                label="Large SUV"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <Typography sx={{ color: "#fff" }}>Vehicle Type</Typography>
            <RadioGroup
              aria-labelledby="vehicleType-radio-buttons-group"
              name="vehicleType"
              // row
              value={vehicleType}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Minivan"
                control={<Radio />}
                label="Minivan"
              />

              <FormControlLabel
                value="Truck"
                control={<Radio />}
                label="Truck"
              />
              <FormControlLabel
                value="Bike"
                control={<Radio />}
                label="Motorcycle"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            margin="normal"
            fullWidth
            required
            label="Make"
            name="vehicleMake"
            placeholder="Your vehicle make"
            value={vehicleMake || ""}
            onChange={handleChange}
            error={!!formErrors.vehicleMake}
            helperText={formErrors.vehicleMake}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            margin="normal"
            fullWidth
            required
            label="Model"
            name="vehicleModel"
            placeholder="Your vehicle model"
            value={vehicleModel || ""}
            onChange={handleChange}
            error={!!formErrors.vehicleModel}
            helperText={formErrors.vehicleModel}
          />
        </Grid> */}

        <Grid item xs={12} sm={6}>
          <Autocomplete
            // disablePortal
            fullWidth
            id="vehicleMakeId"
            value={vehicleMake}
            disabled={vehicleType === "Bike"}
            inputValue={inputMake}
            onChange={handleVehicleMake}
            onInputChange={(event, newInputValue) => {
              setInputMake(newInputValue);
            }}
            options={makeOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Make"
                variant="standard"
                margin="normal"
                fullWidth
                placeholder="Your vehicle make"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            disablePortal
            fullWidth
            id="vehicleModel"
            disabled={!vehicleMake || vehicleMake === "Other"}
            value={vehicleModel}
            onChange={handleVehicleModel}
            options={modelOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Model"
                variant="standard"
                margin="normal"
                fullWidth
                placeholder="Your vehicle model"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DatePicker
            views={["year"]}
            label="Year"
            closeOnSelect
            value={vehicleYear}
            minDate={dayjs().year(1950)}
            maxDate={dayjs().add(1, "year")}
            onChange={handleYearChange}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={null}
                variant="standard"
                margin="normal"
                fullWidth
                placeholder="Your vehicle year"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            margin="normal"
            fullWidth
            select
            SelectProps={{ native: true }}
            label="Color"
            name="vehicleColor"
            placeholder="Your vehicle color"
            value={vehicleColor || ""}
            onChange={handleChange}
            error={!!formErrors.vehicleColor}
            helperText={formErrors.vehicleColor}
          >
            <option value=""> </option>
            <option value="Black">Black</option>
            <option value="White">White</option>
            <option value="Silver">Silver</option>
            <option value="Gray">Gray</option>
            <option value="Red">Red</option>
            <option value="Blue">Blue</option>
            <option value="Blue">Other</option>
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
          <FormControl fullWidth>
            <FormLabel
              id="vehicleCondition-radio-buttons-group"
              required
              focused={false}
            >
              Please choose if your vehicle is brand new (under 4000 km).
            </FormLabel>
            <RadioGroup
              aria-labelledby="vehicleCondition-radio-buttons-group"
              name="vehicleCondition"
              // row
              value={vehicleCondition}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Brand New"
                control={<Radio />}
                label="Brand New (under 4000 km)"
              />
              <FormControlLabel value="Used" control={<Radio />} label="Used" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
          <FormGroup>
            <FormLabel id="vehicleProtection-checkbox">
              Please choose if your vehicle is ceramic coated or wrapped.
            </FormLabel>
            <FormControlLabel
              label="Ceramic coated"
              control={
                <Checkbox
                  name="coated"
                  checked={coated}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "coated-checkbox" }}
                />
              }
            />
            <FormControlLabel
              label="Wrapped"
              control={
                <Checkbox
                  name="ppf"
                  checked={ppf}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "ppf-checkbox" }}
                />
              }
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
          <FormLabel>
            Is there anything else we need to know about your vehicle?
          </FormLabel>
          <TextField
            sx={{ mt: 0 }}
            variant="standard"
            margin="normal"
            fullWidth
            multiline
            maxRows={3}
            label="Additional Note"
            name="vehicleAdditionalNote"
            value={vehicleAdditionalNote || ""}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          // onClick={handleNext}
          onClick={isValid ? handleNext : null}
        >
          Next
        </Button>
      </Box>
    </>
  );
};

export default VehicleInfo;
