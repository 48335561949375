import { Grid, Typography, Divider } from "@mui/material";

const FetchError = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ m: 2 }}>
          <Typography variant="h6" align="center">
            Something went wrong!
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{ m: 2 }}>
          <Typography variant="body1" align="center">
            Please try again later or call our office at +1 (604) 568-3110
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default FetchError;
