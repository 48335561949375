import { useState, useEffect } from "react";
import { Grid, Typography, Button, Box, Paper, Divider } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import api from "../api/posts";

const SelectAddOn = ({
  selectedAddons,
  handleNext,
  handleBack,
  handleChange,
  values: { vehicleType, packageId, addonInfo },
}) => {
  const [addons, setAddons] = useState([]);

  useEffect(() => {
    const addonList = async () => {
      try {
        const response = await api.post("app/addon/", {
          packageId: packageId,
          type: vehicleType,
        });
        console.log(response.data);
        const data = response.data;
        setAddons(data);
      } catch (err) {
        if (err.response) {
          // Not in the 200 response range
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
          // setFetchError(true);
        } else {
          console.log(err);
          // setFetchError(true);
        }
      }
    };

    addonList();
  }, [packageId, vehicleType]);

  const handleEstimatedTime = (addon) => {
    if (!addon.estimated_extra_time || addon.estimated_extra_time === 0) {
      return "N/A";
    } else if (addon.title.toLowerCase().includes('ozone')) {
      return "8 hours";
    } else if (addon.estimated_extra_time === 1) {
      return `${addon.estimated_extra_time} hour`;
    } else {
      return `${addon.estimated_extra_time} hours`;
    }
  };

  // console.log('addonInfo', addonInfo)
  console.log("selectedAddons", selectedAddons);

  if (addons.length === 0) {
    return (
      <>
        <Grid container spacing={2} sx={{ minHeight: 300 }}>
          <Grid item xs={12} sx={{ m: 2 }}>
            <Typography variant="h6" align="center">
              Please select the addons - optional
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sx={{ m: 3 }}>
            <Typography variant="body2" align="center">
              No addons available for this package
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
          <Button
            variant="contained"
            disabled={false}
            color="primary"
            onClick={handleNext}
          >
            Next
          </Button>
        </Box>
      </>
    );
  }

  return (
    <>
      <Grid container spacing={2} sx={{ minHeight: 300 }}>
        <Grid item xs={12} sx={{ m: 2 }}>
          <Typography variant="h6" align="center">
            Please select the addons
          </Typography>
          <Divider />
        </Grid>

        {addons
          .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
          .map((addon) => {
            let isSelected = false;
            for (let i = 0; i < selectedAddons.length; i++) {
              if (selectedAddons[i].id === addon.id) {
                isSelected = true;
                break;
              }
            }

            return (
              // <Grid item xs={4} sm={4} key={addon.id}>
              //   <Paper
              //     key={addon.id}
              //     sx={{
              //       justifyContent: "center",
              //       p: 2,
              //       margin: "auto",
              //       maxWidth: 600,
              //       flexGrow: 1,
              //       backgroundColor:
              //         // addonInfo?.title === addon.title
              //         isSelected
              //           ? "selectedc.main"
              //           : "selectc.main",
              //     }}
              //   >
              //     <Grid container spacing={2} justifyContent="center">
              //       <Grid item>
              //         <ButtonBase sx={{ width: 128, height: 128 }}>
              //           <Img alt="complex" src={require("./pkgcat/Tiago2.png")} />
              //         </ButtonBase>
              //       </Grid>
              //       <Grid item xs={12} sm container>
              //         <Grid item xs container direction="column" spacing={2}>
              //           <Grid item xs>
              //             <Typography gutterBottom variant="h6" component="div">
              //               {addon.title}
              //             </Typography>
              //             <Typography variant="body2" gutterBottom>
              //               {addon.description}
              //             </Typography>
              //             <Typography variant="body2" color="text.secondary">
              //               Estimated Time: {handleEstimatedTime(addon)}
              //             </Typography>
              //           </Grid>
              //           <Grid item>
              //             <Button
              //               id={addon.title}
              //               name="addonInfo"
              //               sx={{
              //                 maxWidth: 300,
              //                 backgroundColor:
              //                   // addonInfo?.title === addon.title
              //                   isSelected
              //                     ? "primary"
              //                     : "buttonSelectc.main",
              //               }}
              //               variant="contained"
              //               value={JSON.stringify(addon)}
              //               onClick={(e) => handleChange(e)}
              //             >
              //               {
              //               // addonInfo?.title === addon.title
              //               isSelected
              //                 ? "Selected"
              //                 : "Select"}
              //             </Button>
              //           </Grid>
              //         </Grid>
              //         <Grid item>
              //           <Typography variant="subtitle1" component="div">
              //             {addon.price} $*
              //           </Typography>
              //         </Grid>
              //       </Grid>
              //     </Grid>
              //   </Paper>
              // </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={addon.id}
                style={{ height: '100%', padding: '20px' }} // Ensure that the grid item takes up 4 columns in the grid
              >
                <Paper
                  sx={{
                    justifyContent: "space-between",
                    p: 2,
                    margin: "auto",
                    maxWidth: 600,
                    flexGrow: 1,
                    backgroundColor: isSelected
                      ? "selectedc.main"
                      : "selectc.main",
                    display: "flex", // Use flex to control the inner layout
                    flexDirection: "column", // Stack children vertically
                    minHeight: "100%", // Match the height of Grid item to make all cards the same height
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    style={{ flexGrow: 1 }}
                  >
                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <Tooltip
                            title={addon.title}
                            enterDelay={300}
                            leaveDelay={200}
                          >
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="div"
                              sx={{
                                display: "-webkit-box",
                                WebkitLineClamp: 1, // Number of lines you want to display
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {addon.title}
                            </Typography>
                          </Tooltip>
                          <Tooltip
                            title={addon.description}
                            enterDelay={300}
                            leaveDelay={200}
                          >
                            <Typography
                              variant="body2"
                              gutterBottom
                              sx={{
                                display: "-webkit-box",
                                WebkitLineClamp: 3, // Adjust the number of lines accordingly
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {addon.description}
                            </Typography>
                          </Tooltip>
                          <Typography variant="body" color="text.secondary">
                            Estimated Time: {handleEstimatedTime(addon)}
                          </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ marginTop: 'auto' }}>
                          <Button
                            id={addon.title}
                            name="addonInfo"
                            sx={{
                              maxWidth: "100%", // Take the full width of the card
                              backgroundColor: isSelected
                                ? "primary"
                                : "buttonSelectc.main",
                            }}
                            variant="contained"
                            value={JSON.stringify(addon)}
                            onClick={(e) => handleChange(e)}
                            fullWidth 
                          >
                            {isSelected ? "Selected" : "Select"}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1" component="div">
                          {addon.price} $*
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          })}
        {vehicleType !== "Bike" && (
          <Grid item xs={12} sx={{ m: 3 }}>
            <Typography variant="body2" align="center">
              * Prices vary based on vehicle size and condition (algae, pet
              hair, mold, etc). Final price given upon vehicle assessment.
            </Typography>
          </Grid>
        )}
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Next
        </Button>
      </Box>
    </>
  );
};

export default SelectAddOn;
