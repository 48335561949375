import {
  Grid,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Box,
  Button,
  Typography,
  Divider,
} from "@mui/material";

const SelectPkg = ({
  handleNext,
  handleBack,
  handleChange,
  values: { packageCategory, vehicleType },
}) => {
  const isValid = packageCategory.length > 0;

  if (vehicleType === "Bike") {
    return (
      <>
        <Grid container spacing={2} sx={{ minHeight: 400 }}>
          <Grid item xs={12} sx={{ m: 2 }}>
            <Typography variant="h6" align="center">
              Please select the package category
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Card
                raised={packageCategory === "bike"}
                sx={{
                  bgcolor:
                    packageCategory === "bike"
                      ? "selectedc.main"
                      : "selectc.main",
                }}
              >
                <CardMedia
                  component="img"
                  height="250"
                  image={require("./pkgcat/bike.jpg")}
                  alt="bike wash category image"
                />
                <CardContent align="center">
                  <Typography variant="h6">Bike Wash</Typography>
                </CardContent>
                <CardActions style={{ justifyContent: "center" }}>
                  <Button
                    name="packageCategory"
                    sx={{
                      width: 300,
                      backgroundColor:
                        packageCategory === "bike"
                          ? "primary"
                          : "buttonSelectc.main",
                    }}
                    variant="contained"
                    id="Bike Wash"
                    value="bike"
                    onClick={(e) => handleChange(e)}
                  >
                    {packageCategory === "bikeWash" ? "Selected" : "Select"}
                    {/* <CheckIcon/> */}
                  </Button>
                </CardActions>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
          <Button
            variant="contained"
            disabled={!isValid}
            color="primary"
            onClick={handleNext}
          >
            Next
          </Button>
        </Box>
      </>
    );
  }

  return (
    <>
      <Grid container spacing={2} sx={{ minHeight: 400 }}>
        <Grid item xs={12} sx={{ m: 2 }}>
          <Typography variant="h6" align="center">
            Please select the package category
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box>
            <Card
              raised={packageCategory === "wash"}
              sx={{
                bgcolor:
                  packageCategory === "wash"
                    ? "selectedc.main"
                    : "selectc.main",
              }}
            >
              <CardMedia
                component="img"
                height="250"
                image={require("./pkgcat/wash.jpg")}
                alt="car wash category image"
              />
              <CardContent align="center">
                <Typography variant="h6">Car Wash</Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "center" }}>
                <Button
                  name="packageCategory"
                  sx={{
                    width: 300,
                    backgroundColor:
                      packageCategory === "wash"
                        ? "primary"
                        : "buttonSelectc.main",
                  }}
                  variant="contained"
                  id="Car Wash"
                  value="wash"
                  onClick={(e) => handleChange(e)}
                >
                  {packageCategory === "carWash" ? "Selected" : "Select"}
                  {/* <CheckIcon/> */}
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box>
            <Card
              raised={packageCategory === "exterior"}
              sx={{
                bgcolor:
                  packageCategory === "exterior"
                    ? "selectedc.main"
                    : "selectc.main",
              }}
            >
              <CardMedia
                component="img"
                height="250"
                image={require("./pkgcat/exterior.jpg")}
                alt="exterior category image"
              />
              <CardContent align="center">
                <Typography variant="h6">Exterior Detail</Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "center" }}>
                <Button
                  name="packageCategory"
                  sx={{
                    width: 300,
                    backgroundColor:
                      packageCategory === "exterior"
                        ? "primary"
                        : "buttonSelectc.main",
                  }}
                  variant="contained"
                  id="Exterior Detail"
                  value="exterior"
                  onClick={(e) => handleChange(e)}
                >
                  {packageCategory === "exterior" ? "Selected" : "Select"}
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box>
            <Card
              raised={packageCategory === "interior"}
              sx={{
                bgcolor:
                  packageCategory === "interior"
                    ? "selectedc.main"
                    : "selectc.main",
              }}
            >
              <CardMedia
                component="img"
                height="250"
                image={require("./pkgcat/interior.jpg")}
                alt="interior category image"
              />
              <CardContent align="center">
                <Typography variant="h6">Interior Detail</Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "center" }}>
                <Button
                  name="packageCategory"
                  sx={{
                    width: 300,
                    backgroundColor:
                      packageCategory === "interior"
                        ? "primary"
                        : "buttonSelectc.main",
                  }}
                  variant="contained"
                  id="Interior Detail"
                  value="interior"
                  onClick={(e) => handleChange(e)}
                >
                  {packageCategory === "interior" ? "Selected" : "Select"}
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box>
            <Card
              raised={packageCategory === "bundle"}
              sx={{
                bgcolor:
                  packageCategory === "bundle"
                    ? "selectedc.main"
                    : "selectc.main",
              }}
            >
              <CardMedia
                component="img"
                height="250"
                image={require("./pkgcat/full.jpg")}
                alt="bundle category image"
              />
              <CardContent align="center">
                <Typography variant="h6">Interior & Exterior Detail</Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "center" }}>
                <Button
                  name="packageCategory"
                  sx={{
                    width: 300,
                    backgroundColor:
                      packageCategory === "bundle"
                        ? "primary"
                        : "buttonSelectc.main",
                  }}
                  variant="contained"
                  id="Full Detail"
                  value="bundle"
                  onClick={(e) => handleChange(e)}
                >
                  {packageCategory === "bundle" ? "Selected" : "Select"}
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box>
            <Card
              raised={packageCategory === "coating"}
              sx={{
                bgcolor:
                  packageCategory === "coating"
                    ? "selectedc.main"
                    : "selectc.main",
              }}
            >
              <CardMedia
                component="img"
                height="250"
                image={require("./pkgcat/ceramic.jpg")}
                alt="coating category image"
              />
              <CardContent align="center">
                <Typography variant="h6">Ceramic Coating</Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "center" }}>
                <Button
                  name="packageCategory"
                  sx={{
                    width: 300,
                    backgroundColor:
                      packageCategory === "coating"
                        ? "primary"
                        : "buttonSelectc.main",
                  }}
                  variant="contained"
                  id="Ceramic Coating"
                  value="coating"
                  onClick={(e) => handleChange(e)}
                >
                  {packageCategory === "coating" ? "Selected" : "Select"}
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box>
            <Card
              raised={packageCategory === "ppf"}
              sx={{
                bgcolor:
                  packageCategory === "ppf" ? "selectedc.main" : "selectc.main",
              }}
            >
              <CardMedia
                component="img"
                height="250"
                image={require("./pkgcat/ppf.jpg")}
                alt="ppf category image"
              />
              <CardContent align="center">
                <Typography variant="h6">Film Protection</Typography>
              </CardContent>
              <CardActions style={{ justifyContent: "center" }}>
                <Button
                  name="packageCategory"
                  sx={{
                    width: 300,
                    backgroundColor:
                      packageCategory === "ppf"
                        ? "primary"
                        : "buttonSelectc.main",
                  }}
                  variant="contained"
                  id="Film Protection"
                  value="ppf"
                  onClick={(e) => handleChange(e)}
                >
                  {packageCategory === "ppf" ? "Selected" : "Select"}
                </Button>
              </CardActions>
            </Card>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
        >
          Next
        </Button>
      </Box>
    </>
  );
};

export default SelectPkg;
